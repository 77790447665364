@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "styles/themeVars";

@mixin navigationLink {
  content: "";
  display: block;
  border: solid;
  border-width: 2px 2px 0 0;
  border-color: #b6b6b6;
  padding: 0.25em;
  transform: rotate(45deg);
  width: 10px;
  height: 11px;
}

@mixin getSubCategoryMainMenuShopByCategoryBaseStyles() {
  flex: 0 1 auto;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  padding: 7px;
  text-decoration: none;
  transition:
    background-color 0.25s ease-in,
    color 0.25s ease-in;
  cursor: pointer;

  &::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px;
    border-color: transparent;
    border-bottom-width: 0;
    border-top-color: #fff;
    margin-right: 5px;
    margin-left: 3px;
    transition:
      border-top-color 0.25s ease-in,
      color 0.25s ease-in;
  }
}

@mixin getCategoryMainMenuMed32Styles() {
  @include getSubCategoryMainMenuShopByCategoryBaseStyles();
  background-color: $primary;
}

@mixin getSubCategoryMainMenuNet32Styles() {
  @include getSubCategoryMainMenuShopByCategoryBaseStyles();
  background-color: #4385f6;
}

@mixin getSubCategoryMainMenuWrapperStyles() {
  position: absolute;
  width: fit-content;
  background: #fff;
  border-radius: 8px;
  min-height: 450px;

  &::after {
    content: "";
    position: absolute;
    top: -8px;
    right: 150px;
    display: inline-block;
    border: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #fff transparent;
  }
}

@mixin getSubCategoryMainMenuContentStyles() {
  .menu-content {
    min-width: 370px;
    padding: 12px 0;
    border-radius: 8px 0 0 8px;
    height: inherit;
    min-height: inherit;

    .main-link {
      .main-link-title {
        color: #1a1a1a;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 24px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      .main-link-title::after {
        @include navigationLink();
      }

      .submenu {
        display: none;
        position: absolute;
        left: 98%;
        top: 0;
        height: 100%;
        min-width: 340px;
        padding: 24px;
        background: #eef4fe;
        border-radius: 0 8px 8px 0;
        overflow: hidden;

        h3 {
          color: #1a1a1a;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          border-bottom: 1px solid #cecece;
          padding-bottom: 16px;
          margin-bottom: 12px;
        }

        .submenu-content {
          height: calc(100% - 54px);
          overflow-y: scroll;
          display: block;

          a {
            display: block;
            text-decoration: none;
            color: #1a1a1a;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            padding: 8px 0;
            margin-bottom: 8px;
            transition: all 0.2s ease-in-out;

            &:hover {
              text-decoration: none;
              color: #4385f6;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &:hover > .main-link-title {
        background-color: #4385f6;
        color: #fff;

        &:after {
          border-color: #fff;
        }
      }

      &:hover > .submenu {
        display: block;
      }
    }
  }
}

.menu-button {
  @include getSubCategoryMainMenuNet32Styles();

  &:hover {
    background-color: #fff;
    color: #4385f6;
  }

  &:hover {
    &:after {
      border-top-color: #4385f6;
    }
  }
}

@include media-breakpoint-between(md, xl) {
  .menu-button {
    @include getSubCategoryMainMenuNet32Styles();
  }

  .menu-wrapper {
    top: 64px;
    left: -28px;
    @include getSubCategoryMainMenuWrapperStyles();
    @include getSubCategoryMainMenuContentStyles();
    &::after {
      right: 200px !important;
    }
  }
}

.menu-wrapper {
  top: -15px;
  left: -275px;
  @include getSubCategoryMainMenuWrapperStyles();
  @include getSubCategoryMainMenuContentStyles();

  @include media-breakpoint-up(md) {
    left: -200px;
    &::after {
      right: 250px !important;
    }
  }
}

.mobile-container {
  .mobile-main-menu {
    padding: 24px;
    display: block;

    .mobile-link {
      color: #212529;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      margin-bottom: 12px;
      cursor: pointer;
    }

    .mobile-nav-link {
      @include navigationLink();
    }
  }
  .mobile-submenu {
    width: 100%;
    height: calc(100% + 50px);
    background: #fff;
    padding: 24px 0;

    .mobile-submenu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px 15px;
      border-bottom: 1px solid #e2e2e2;

      h3 {
        color: #1a1a1a;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin: 0;
      }

      .mobile-submenu-head {
        color: #4385f6;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        min-width: 60px;
        margin-right: 25px;

        svg {
          width: 14px;
          height: 14px;
          fill: none;
        }
      }
    }

    .mobile-submenu-links {
      height: calc(100% - 84px);
      overflow: auto;
      padding: 12px 24px 0;

      a {
        color: #212529;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        margin-bottom: 12px;
        cursor: pointer;
      }

      .mobile-submenu-nav-links {
        @include navigationLink();
      }
    }
  }
}
